import React, { useState } from "react";
import tw from "twin.macro";
import { motion } from "framer-motion";
import styled from "styled-components";
import ConsultingImageSrc from "images/gis-hanoit-back.png";
import DevelopmentImageSrc from "images/gis-hanoit-back-2.png";
import StaffingImageSrc from "images/gis-hanoit-back-3.png";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import { OpacityOverlay } from "components/misc/OpacityOverlay";
import { MainContainer } from "components/misc/MainContainer";
import { useLazyLoad } from "../hook/useLazyLoad.js";

// Array de imágenes que cambiarán en el Swiper
const images = [ConsultingImageSrc, DevelopmentImageSrc, StaffingImageSrc];
const dynamicTexts = ["Consulting", "Development", "Staffing"];
const textColor = "#6415ff";

const TextContainer = styled(motion.span)`
  display: inline-block;
`;

const BlackOverlay = styled.div`
  ${tw`absolute inset-0 bg-black bg-opacity-75 rounded-md`}
  @media (min-width: 1368px) {
    display: none;
  }
`;

const textVariants = {
  hidden: { opacity: 0, y: -50 },
  visible: { opacity: 1, y: 0 },
};

const Title = styled.h1`
  ${tw`text-3xl sm:text-3xl lg:text-5xl font-bold mb-4 relative p-5 z-10`}
  @media (max-width: 1368px) {
    ${tw`text-2xl mb-1 p-2`}
  }
  @media (max-width: 1024px) {
    ${tw`text-xl mb-0 p-2`}
  }
  @media (max-width: 640px) {
    ${tw`text-base`}
  }
`;

const Subtitle = styled.p`
  ${tw`text-lg sm:text-xl lg:text-2xl relative p-5 z-10`}
  @media (max-width: 1368px) {
    ${tw`text-2xl`}
  }
  @media (max-width: 1024px) {
    ${tw`text-xl p-1`}
  }
  @media (max-width: 640px) {
    ${tw`text-xs`}
  }
`;

const AbsoluteDiv = styled.div`
  ${tw`absolute inset-0 z-10 flex items-center pointer-events-none px-1 lg:px-1`}

  @media (max-width: 1024px) {
    ${tw`justify-center items-start`} // Centrar horizontalmente y alinear en la parte superior
    padding-top: 6rem; // Ajustar espacio en la parte superior
  }

  @media (max-width: 640px) {
    ${tw`justify-center items-end`} // Centrar horizontalmente y alinear en la parte inferior
    padding-bottom: 2rem; // Ajustar espacio en la parte inferior
  }
`;

const TextWrapper = styled.div`
  ${tw`text-white ml-20 lg:max-w-2xl relative`}

  @media (max-width: 1024px) {
    ${tw`ml-0`} // Eliminar margen a la izquierda en pantallas medianas
    text-align: center; // Centrar el texto en pantallas medianas
  }

  @media (max-width: 640px) {
    ${tw`ml-0 mb-0`} // Eliminar margen a la izquierda y abajo en pantallas pequeñas
    text-align: center; // Centrar el texto en pantallas pequeñas
  }
`;

const SlideBackground = styled.div`
  background-position: right;
  background-repeat: no-repeat;
  background-size: 60%;
  ${tw`h-[90vh] relative w-full`}
  @media (max-width: 1368px) {
    ${tw`h-[70vh] relative w-full`}
    background-size: 90%;
  }
  @media (max-width: 1280px) {
    ${tw`h-[50vh] relative w-full`}
    background-position: center;
  }
  @media (max-width: 820px) {
    ${tw`h-[40vh] fixed w-full`}
  }
`;

const LazyLoadBackground = ({ imageSrc }) => {
  const { isLoaded, containerRef } = useLazyLoad(imageSrc);  // Usamos el hook

  return (
    <SlideBackground
      ref={containerRef}
      style={{
        backgroundImage: isLoaded ? `url(${imageSrc})` : "none",
      }}
    />
  );
};

export default () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <MainContainer>
      <OpacityOverlay />
      <AbsoluteDiv>
        <TextWrapper>
          <BlackOverlay />
          <Title>
            Leading GIS{" "}
            <TextContainer
              initial="hidden"
              animate="visible"
              variants={textVariants}
              transition={{ duration: 0.5, ease: "easeOut" }}
              key={activeIndex}
            >
              <span style={{ color: textColor }}>{dynamicTexts[activeIndex]}</span>
            </TextContainer>
          </Title>
          <Subtitle>
            "Innovative Geospatial Solutions for Business Success"
          </Subtitle>
        </TextWrapper>
      </AbsoluteDiv>

      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        className="swiper-background h-full w-full"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <LazyLoadBackground imageSrc={image} />
          </SwiperSlide>
        ))}
      </Swiper>
    </MainContainer>
  );
};
