import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { motion } from "framer-motion";
import Header, {
  NavLink,
  NavLinks,
  LogoLink,
  NavToggle,
  DesktopNavLinks
} from "../headers/light.js";

// Estilos del header para que permanezca fijo y no se recorte
const StyledHeader = motion(styled.div`
  ${tw`fixed top-0 left-0 w-full z-50 transition-all duration-300 ease-in-out`}

  // Fondo dinámico: transparente al inicio, semitransparente al hacer scroll
  background-color: ${({ isSticky }) => (isSticky ? "rgba(51, 0, 102, 0.8)" : "rgba(51, 0, 102, 1)")};

  // Transición suave para los elementos internos cuando el header cambia de tamaño
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    transition: all 0.3s ease-in-out;
    ${tw`p-4 text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  
  ${NavToggle}.closed {
    transition: all 0.3s ease-in-out;
    ${tw`md:pr-8 text-gray-100 hover:text-primary-500`}
  }

  transform: translateY(0);
`);

// Asegurar que el contenido del header esté centrado
const HeaderContainer = styled.div`
  ${tw`flex items-center justify-between h-full`}
`;

export default function StickyHeader() {
  const [isSticky, setSticky] = useState(false);

  const handleScroll = () => {
    // Cambiar el estado isSticky según el valor de scrollY
    if (window.scrollY > 0) {
      setSticky(true);
    } else {
      setSticky(false); // Si scroll es menor o igual a 66px, regresa al estado original
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/aboutus">About Us</NavLink>
      <NavLink href="#our-services">Our Services</NavLink>
      <NavLink href="#our-portfolio">Our Portfolio</NavLink>
      <NavLink href="#our-expertise">Our Expertise</NavLink>
      <NavLink href="#contact-us">Contact Us</NavLink>
    </NavLinks>,
  ];

  return (
    <StyledHeader isSticky={isSticky}>
      <HeaderContainer>
        {/* Contenido del header centrado */}
        <Header links={navLinks} />
      </HeaderContainer>
    </StyledHeader>
  );
}
