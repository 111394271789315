import React from "react";
import Layout from "layouts/MainLayout";
import MainBackGround from "components/features/BackgroundAsImage.js";
import Expertise from "components/features/Expertise.js";
import Team from "components/features/Team.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Services from "components/features/Services.js"
import ContactUsFormFull from "components/forms/TwoColContactUsWithIllustrationFullForm";
import Clients from "components/features/ClientsSection";


export default () => (
  <Layout>
    <MainBackGround /> 
    <Clients />
    <Services />
    <Portfolio />
    <Expertise />
    <Team />
    <ContactUsFormFull />
  </Layout>
);
