import { useState, useEffect, useRef } from "react";

// Custom Hook para Lazy Loading
export const useLazyLoad = (imageSrc, rootMargin = "200px") => {
  const [isLoaded, setIsLoaded] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const node = containerRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsLoaded(true);
          observer.disconnect(); // Desconectar cuando se haya cargado
        }
      },
      { rootMargin }
    );

    if (node) {
      observer.observe(node);
    }

    return () => {
      if (node) {
        observer.unobserve(node);
      }
    };
  }, [imageSrc, rootMargin]);

  return { isLoaded, containerRef };
};
