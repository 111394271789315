import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainLandingPage from "./pages/LandingPage"
import AboutUs from "pages/AboutUs";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainLandingPage />} />
        <Route path="/aboutus" element={<AboutUs />} />
      </Routes>
    </Router>
  );
}
