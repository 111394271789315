import React from "react";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ThreeColSimple.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import Layout from "layouts/MainLayout";
import aboutUsImg from "images/aboutus.jpg"
import visionImg from "images/vision.jpg"

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <Layout>
      <MainFeature1
        subheading={<Subheading>About HanoIT LLC</Subheading>}
        heading="We are your geospatial solutions partner."
        description={ <>
          Founded in 2001, HanoIT specializes in providing customized geospatial solutions for sectors such as telecommunications, utilities, public safety, emergency services, real estate, natural resources, local and national governments, infrastructure, and IoT fleet management. Our solutions are tailored to meet the unique business objectives and technological requirements of our clients.
          <br /><br />
          We are experts in using Esri products, primarily the ArcGIS platform, and our team is composed of certified professionals with extensive experience in leveraging these tools. Additionally, we specialize in integrating other GIS platforms, whether open-source or commercial, ensuring seamless and efficient implementation that maximizes the value of geospatial data.
        </>}
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        primaryButtonUrl="/#our-portfolio"
        imageSrc={aboutUsImg}
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="We aim to lead the geospatial industry by driving digital transformation."
        buttonRounded={false}
        description={<>Our vision is to be leaders in the development and implementation of geospatial solutions that drive the digital transformation of businesses and organizations worldwide. We strive to provide cutting-edge, innovative tools that enhance decision-making, optimize resources, and contribute to the sustainable growth of our clients.</>}
        primaryButtonText="Our Services"
        primaryButtonUrl="/#our-services"
        imageSrc={visionImg}
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We are guided by the following core principles:"
        description=""
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description: "We are committed to providing round-the-clock support to ensure your projects run smoothly and efficiently."
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description: "Our team consists of highly skilled and certified professionals who collaborate to deliver the best geospatial solutions tailored to your needs."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: "Our primary goal is to exceed customer expectations by delivering innovative and customized geospatial solutions that address your business challenges."
          },
        ]}
        linkText=""
      />
    </Layout>
  );
};
